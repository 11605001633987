import React, { useState, useEffect } from "react";
import Navbar from "./Components/Navbar/Navbar";
import Navbarmobile from "./Components/Navbar/Navbarmobile";
import Home from "./Components/Pages/Home";
import Services from "./Components/Pages/Services";
import Teams from "./Components/Pages/Teams";
import About from "./Components/Pages/About";
import Contact from "./Components/Pages/Contact";
import Footer from "./Components/Pages/Footer";
function App() {
  const endDate = new Date("2024-12-05T00:00:00").getTime();

  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date().getTime();
      const difference = endDate - now;

      if (difference <= 0) {
        setTimeLeft(null); // Timer finished
      } else {
        setTimeLeft({
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor(
            (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          ),
          minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
          seconds: Math.floor((difference % (1000 * 60)) / 1000),
        });
      }
    };

    const timer = setInterval(calculateTimeLeft, 1000);
    calculateTimeLeft(); // Initial calculation

    return () => clearInterval(timer); // Cleanup on unmount
  }, [endDate]);
  const containerStyle = {
    position: "fixed",
    top: "10px",
    left: "50%",
    transform: "translateX(-50%)",
    width: "200px",
    backgroundColor: "#212EA0",
    color: "white",
    textAlign: "center",
    padding: "10px",
    borderRadius: "10px",
    fontSize: "16px",
    zIndex: 1000,
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Optional shadow for style
  };

  return (
    <div className="App">
      <div style={containerStyle}>
      {timeLeft ? (
        <span>
          Under Maintenance: <br />
          {timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m {timeLeft.seconds}s
        </span>
      ) : (
        <span>Launch Day!</span>
      )}
    </div>
      <Navbar />
      {/* <Navbarmobile/> */}
      <Home />
      <Services />
      <Teams />
      <About />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;

import React from 'react'
import './Footer.css'
import About from '../Assets/Img/about.png'
import Blogs from '../Assets/Img/blogs.png'
import Information from '../Assets/Img/information.png'
import Facebook from '../Assets/Img/facebook.png'
import blog1 from "../Assets/Img/blog1.jpg";
import blog2 from "../Assets/Img/blog2.jpg";
import mail_icon from "../Assets/Img/mail-icon.png";
import phone_icon_purple from "../Assets/Img/phone-purple.png";
import location_icon_purple from "../Assets/Img/location-purple.png";
import mail_icon_purple from "../Assets/Img/mail-purple.png";
import { Link } from "react-scroll";


const facebookPostLinks = [
  "https://www.facebook.com/photo/?fbid=122183425622216450&set=a.122106533270216450",
  "https://www.facebook.com/photo?fbid=122176978226216450&set=a.122106533270216450",
  "https://www.facebook.com/photo?fbid=122133305558216450&set=a.122106533270216450",
  "https://www.facebook.com/photo?fbid=122115472508216450&set=a.122106533270216450",
  "https://www.facebook.com/photo?fbid=122112006056216450&set=a.122106533270216450",
  "https://www.facebook.com/photo?fbid=122106530048216450&set=a.122106533270216450",
  "https://www.facebook.com/photo?fbid=122108159762216450&set=a.122106533270216450",
  "https://www.facebook.com/photo?fbid=122110765328216450&set=a.122106533270216450",
  "https://www.facebook.com/photo?fbid=122119375712216450&set=a.122106533270216450"
]



const footer = () => {
  return (
    <div className='main-contain'>
      {/* <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid02Df28WyuRjrVYqtf9aHzrSFWS98rRtzvVmKBmFfAxmL3L1cP2H6NGYmwvWgfG2mLql%26id%3D61556493519496&show_text=true&width=500" width="500" height="250" style={{ border: "none", overflow: "hidden" }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe> */}
      <div className='main-footer'>
        <div className='footer-container'>
          <ul>
            <li>
              <h1><div><img src={About} alt="" /></div>About</h1>
              <p><img className='about-icons' src={location_icon_purple} alt="" /> Kamal Pokhari, Kathmandu, Bagmati provience, 44600, Nepal</p>
              <p><img className='about-icons' src={phone_icon_purple} alt="" /> +977 9807086924</p>
              <p><img className='about-icons' src={mail_icon_purple} alt="" /> tridevinnovation@gmail.com</p>
              <div className="footer-mail">
                <input type="email" placeholder='Enter email address' />
                <button><img src={mail_icon} alt="" /></button>
              </div>
            </li>
            <li>
              <h1><div><img src={Blogs} alt="" /></div>Latest Blogs</h1>
              <p className='footer-blog-tittle'><img src={blog1} className="blog-img" alt="" /> Do you need a website for your business? contact Tridev Inno....See more</p>
              <p className='footer-blog-tittle'><img src={blog2} className="blog-img" alt="" /> Are you in search of portfolio website at low price? Cont....See more</p>

            </li>
            <li>
              <h1><div><img src={Information} alt="" /></div>Information</h1>
              <ul className='nav-info-links'>
                <li><Link to="Hero-main" smooth={true} offset={0} duration={500}>Home</Link></li>
                <li><Link to="Main-ser" smooth={true} offset={-90} duration={500}>Services</Link></li>
                <li><Link to="our-teams" smooth={true} offset={-90} duration={500}>Team</Link></li>
                <li><Link to="about-page" smooth={true} offset={-90} duration={500}>About</Link></li>
                <li><Link to="Home" smooth={true} offset={-90} duration={500}>Blogs&#x2197;</Link></li>
                <li><Link to="Contact-page" smooth={true} offset={-90} duration={500}>Contact</Link></li>
              </ul>
            </li>
            <li>
              <h1><div><img src={Facebook} alt="" /></div>Facebook</h1>
              <div class="fb-grid-container">
                <a href={facebookPostLinks[0]} target="_blank" rel="noopener noreferrer">
                  <img src={"https://scontent.fktm7-1.fna.fbcdn.net/v/t39.30808-6/461484613_122183425628216450_8829031776331824763_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=127cfc&_nc_ohc=Td8xjwzmdroQ7kNvgFl3pk7&_nc_zt=23&_nc_ht=scontent.fktm7-1.fna&_nc_gid=Ar6D-KfksKf9sYNp_N9b-P4&oh=00_AYCFHrl3f0jdYZGIlfm2DzggiwP3AoE1fdT86UUNd82ihw&oe=67340BF3"} alt="" className="grid-photo" />
                </a>
                <a href={facebookPostLinks[1]} target="_blank" rel="noopener noreferrer">

                  <img src={"https://scontent.fktm7-1.fna.fbcdn.net/v/t39.30808-6/457821218_122176978238216450_5453562213687165239_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=127cfc&_nc_ohc=vb-eLvPtAaQQ7kNvgGMODyB&_nc_zt=23&_nc_ht=scontent.fktm7-1.fna&_nc_gid=A2eezEEGZYrr3olzVp6B_U-&oh=00_AYAHgD8tvCGq5OXCOpmm0PYPJ6mbgt7cQ8E4fCbVMbuNYQ&oe=673405B5"} alt="" className="grid-photo" />
                </a>
                <a href={facebookPostLinks[2]} target="_blank" rel="noopener noreferrer">

                  <img src={"https://scontent.fktm10-1.fna.fbcdn.net/v/t39.30808-6/435109875_122133305552216450_3450185599568017174_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=127cfc&_nc_ohc=-uM70mtwudwQ7kNvgGu5HQT&_nc_zt=23&_nc_ht=scontent.fktm10-1.fna&_nc_gid=A_2yK4QfRQMinJyrEE1s0LJ&oh=00_AYDpWfaVkHrglhLrJencgK0Ui9fmgGwNAobcqQDEvVrGxg&oe=673436DF"} alt="" className="grid-photo" />
                </a>
                <a href={facebookPostLinks[3]} target="_blank" rel="noopener noreferrer">

                  <img src={"https://scontent.fktm10-1.fna.fbcdn.net/v/t39.30808-6/430934438_122115472502216450_6234703275658305392_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=127cfc&_nc_ohc=ricrsYl0BxwQ7kNvgGWxBjj&_nc_zt=23&_nc_ht=scontent.fktm10-1.fna&_nc_gid=AyNIV3xmHnuatQAUVP_hO25&oh=00_AYDAsNyqzTaTR9uVulaz_Nogq0EPZfwOmkE5NhKrZrnRDQ&oe=67341EC5"} alt="" className="grid-photo" />
                </a>
                <a href={facebookPostLinks[4]} target="_blank" rel="noopener noreferrer">

                  <img src={"https://scontent.fktm10-1.fna.fbcdn.net/v/t39.30808-6/430259489_122112006050216450_2259044273520373332_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=127cfc&_nc_ohc=PqzYBZi_jL8Q7kNvgF4JLCm&_nc_zt=23&_nc_ht=scontent.fktm10-1.fna&_nc_gid=A5XWSqNhgObsjvfeVWd_EWE&oh=00_AYAoKbhReIeB-t1wHyZY3KEdxQ6PR5zGPf85nDDf3ozifQ&oe=67343916"} alt="" className="grid-photo" />
                </a>
                <a href={facebookPostLinks[5]} target="_blank" rel="noopener noreferrer">

                  <img src={"https://scontent.fktm7-1.fna.fbcdn.net/v/t39.30808-6/422654717_122106530042216450_1073158630232849842_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=127cfc&_nc_ohc=CVoiT2ZrPLgQ7kNvgHhDoBa&_nc_zt=23&_nc_ht=scontent.fktm7-1.fna&_nc_gid=ArqFYkbsQWQthC2pYWJOgaP&oh=00_AYCPiz_ND-Cz8FelKp9i3TpFeBDuedJpgMkbvyYaKAeV3Q&oe=67341A4C"} alt="" className="grid-photo" />
                </a>
                <a href={facebookPostLinks[6]} target="_blank" rel="noopener noreferrer">

                  <img src={"https://scontent.fktm7-1.fna.fbcdn.net/v/t39.30808-6/424961437_122108159750216450_4829018785222694163_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=127cfc&_nc_ohc=SBQRNu6k9BQQ7kNvgHmQwET&_nc_zt=23&_nc_ht=scontent.fktm7-1.fna&_nc_gid=ALl-LCm93-PSCqI82_WEJt6&oh=00_AYB02uhz-H3umUpWQxnQLw3VcoUeyEG0nLF9VryAB-nt9g&oe=67340E70"} alt="" className="grid-photo" />
                </a>
                <a href={facebookPostLinks[7]} target="_blank" rel="noopener noreferrer">

                  <img src={"https://scontent.fktm10-1.fna.fbcdn.net/v/t39.30808-6/422759772_122110765322216450_4358988470704863478_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=127cfc&_nc_ohc=E6q540EUC0cQ7kNvgFXEm2A&_nc_zt=23&_nc_ht=scontent.fktm10-1.fna&_nc_gid=AI-nTTRtZBHqI4zmKglN8B2&oh=00_AYBuq7raCmrLcuzxwV2ylKrAP0x_ymfxgowEVqgUvrEaEw&oe=67341A7F"} alt="" className="grid-photo" />
                </a>
                <a href={facebookPostLinks[8]} target="_blank" rel="noopener noreferrer">

                  <img src={"https://scontent.fktm7-1.fna.fbcdn.net/v/t39.30808-6/430289368_122119375706216450_2220297044078394576_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=127cfc&_nc_ohc=K8QKuThvlNoQ7kNvgE3qoQ-&_nc_zt=23&_nc_ht=scontent.fktm7-1.fna&_nc_gid=AxjVC1JGoL-KcbYWA0o8MTw&oh=00_AYAuE8YukwN9Ju9cirDtQ0dkHjuPzzCgmVNc4tYYiYyxkw&oe=67341625"} alt="" className="grid-photo" />
                </a>

              </div>
            </li>
          </ul>
        </div>
      </div>

      <hr />
      <h1 className='copyright-text'>Copyright &#169; Tridev Innovation | All Right Reserved</h1>
    </div>
  )
}

export default footer

import React from "react";
import "./Contact.css";
import msg_icon from "../Assets/Img/msg-icon.png";
import mail_icon from "../Assets/Img/mail-icon.png";
import phone_icon from "../Assets/Img/phone-icon.png";
import location_icon from "../Assets/Img/location-icon.png";

const Contact = () => {
  return (
    <div className="Contact-page">
      <h1 className="ser-topic1">Contact Us</h1>
      <div className="contact">
        <div className="contact-col">
          <h3>
            Send us a message <img src={msg_icon} alt="" />
          </h3>
          <p>
            Feel free to reach out through contact form or find our contact
            information below. Your feedback, questions, and suggestions are
            important to us as we strive to provide exceptional service to our
            clients.
          </p>
          <ul>
            <li>
              <img src={mail_icon} alt="" />
              tridevinnovation.com
            </li>
            <li>
              <img src={phone_icon} alt="" />
              +977 9807086924
            </li>
            <li>
              <img src={location_icon} alt="" />
              Kamal Pokhari <br /> Kathmandu, Nepal
            </li>
          </ul>
        </div>
        <div className="contact-col">
          <form>
            <label>Your name</label>
            <input
              type="text"
              name="name"
              placeholder="Enter your name"
              required
            />
            <label>Phone number</label>
            <input
              type="tel"
              name="phone"
              placeholder="Enter your mobie number"
              required
            />
            <label>Write your message</label>
            <textarea
              name="message"
              rows="6"
              placeholder="Enter your messege"
              required
            ></textarea>
            <button type="submit" className="explore-btn">
              Submit now
            </button>
          </form>
          <span></span>
        </div>
      </div>
    </div>
  );
};

export default Contact;

import React from "react";
import "./Services.css";
import { Zoom } from "react-reveal";

import Showcase from "../Assets/Img/showcase.png";
import Showcase1 from "../Assets/Img/app-showcase.png"
import Showcase2 from "../Assets/Img/iot.png"
import Showcase3 from "../Assets/Img/uiux.png"
import Showcase4 from "../Assets/Img/dm.png"
import Showcase5 from "../Assets/Img/it.png"
const Services = () => {
  return (
    <div className="Main-ser">
      <h1 className="ser-topic1">Our Services</h1>
      <Zoom left duration={400} delay={100}>
        <div className="ser1">
          <div className="showcase1" id="show1">
            <img src={Showcase1} alt="" />
          </div>
          <div className="dis1">
            <h1>Mobile Applications</h1>
            <p>
              "As a digital innovation leader, we specialize in crafting
              cutting-edge mobile apps, redefining experiences. Our skilled team
              delivers bespoke solutions for customer engagement, streamlined
              operations, and brand elevation."
            </p>
            <button className="explore-btn">
              Explore<span>&#11166;</span>
            </button>
          </div>
        </div>
      </Zoom>
      <Zoom right duration={400} delay={100}>
        <div className="ser1" id="to-hide">
          <div className="dis1">
            <h1>Web Development</h1>
            <p>
              "We design responsive, visually stunning websites, and dynamic web
              applications, leveraging cutting-edge technology for seamless user
              experiences, elevating your online presence and digital success."
            </p>
            <button className="explore-btn">
              Explore<span>&#11166;</span>
            </button>
          </div>
          <div className="showcase1" id="show2">
            <img src={Showcase} alt="" />
          </div>
        </div>





        <div className="ser1" id="to-show">
          <div className="showcase1" id="show2">
            <img src={Showcase} alt="" />
          </div>
          <div className="dis1">
            <h1>Web Development</h1>
            <p>
              "We design responsive, visually stunning websites, and dynamic web
              applications, leveraging cutting-edge technology for seamless user
              experiences, elevating your online presence and digital success."
            </p>
            <button className="explore-btn">
              Explore<span>&#11166;</span>
            </button>
          </div>
        </div>
      </Zoom>

      <Zoom left duration={400} delay={100}>
        <div className="ser1">
          <div className="showcase1" id="show3">
            <img src={Showcase2} alt="" />
          </div>
          <div className="dis1">
            <h1>IoT Development</h1>
            <p>
              “From smart devices to robust solutions, we innovate for connected
              experiences. Seamlessly integrate IoT technology for enhanced
              efficiency and real-time insights."
            </p>
            <button className="explore-btn">
              Explore<span>&#11166;</span>
            </button>
          </div>
        </div>
      </Zoom>


      <Zoom right duration={400} delay={100}>
        <div className="ser1" id="to-hide">
          <div className="dis1">
            <h1>IT Support and Services</h1>
            <p>
              "“we provide top-notch IT support and services. Our expert team
              ensures seamless operations, cybersecurity, and tech solutions
              tailored to your business needs."
            </p>
            <button className="explore-btn">
              Explore<span>&#11166;</span>
            </button>
          </div>
          <div className="showcase1" id="show4">
            <img src={Showcase5} alt="" />
          </div>
        </div>



        <div className="ser1" id="to-show">
          <div className="showcase1" id="show4">
            <img src={Showcase5} alt="" />
          </div>
          <div className="dis1">
            <h1>IT Support and Services</h1>
            <p>
              "“we provide top-notch IT support and services. Our expert team
              ensures seamless operations, cybersecurity, and tech solutions
              tailored to your business needs."
            </p>
            <button className="explore-btn">
              Explore<span>&#11166;</span>
            </button>
          </div>
        </div>
      </Zoom>




      <Zoom left duration={400} delay={100}>
        <div className="ser1">
          <div className="showcase1 graphicdesign" id="show5">
            <img src={Showcase3} alt="" />
          </div>
          <div className="dis1">
            <h1>Graphic Design</h1>
            <p>
              "Elevate your brand with our graphic design expertise. From
              eye-catching visuals to cohesive branding, we craft compelling
              designs that resonate with your audience and leave a lasting
              impression."
            </p>
            <button className="explore-btn">
              Explore<span>&#11166;</span>
            </button>
          </div>
        </div>
      </Zoom>



      <Zoom right duration={400} delay={100}>
        <div className="ser1" id="to-hide">
          <div className="dis1">
            <h1>Digital Marketing </h1>
            <p>
              "Maximize your online presence with our digital marketing solutions.
              We specialize in strategic campaigns, SEO, social media, and
              analytics to boost your brand's visibility and engagement."
            </p>
            <button className="explore-btn">
              Explore<span>&#11166;</span>
            </button>
          </div>
          <div className="showcase1" id="show6">
            <img src={Showcase4} style={{ transform: 'scaleX(-1)' }} alt="" />
          </div>
        </div>



        <div className="ser1" id="to-show">
          <div className="showcase1" id="show6">
            <img src={Showcase4} alt="" />
          </div>
          <div className="dis1">
            <h1>Digital Marketing </h1>
            <p>
              "Maximize your online presence with our digital marketing solutions.
              We specialize in strategic campaigns, SEO, social media, and
              analytics to boost your brand's visibility and engagement."
            </p>
            <button className="explore-btn">
              Explore<span>&#11166;</span>
            </button>
          </div>
        </div>
      </Zoom>


    </div>
  );
};

export default Services;

import React from 'react'
import './About.css'
import NT from '../Assets/Logo/Partners-logo/nepatronix_logo.png'
import HC from '../Assets/Logo/Partners-logo/HimalayanCollege.svg'
import HS from '../Assets/Logo/Partners-logo/himalayanSolution.png'
const About = () => {
  return (
    <div className='about-page'>
      <h1 className='ser-topic1'>About Us</h1>
      <p>At Tridev Innovation, we’re more than a software company—we’re a team of passionate professionals united by a shared vision: to redefine possibilities through technology. With years of expertise in software development and IT solutions, our mission is to empower businesses and individuals with innovative tools that drive growth and success.

        From custom software development to end-to-end IT services, we pride ourselves on delivering excellence in every project. Guided by our core values of innovation, integrity, and excellence, we go beyond meeting expectations—we aim to exceed them, creating solutions that leave a lasting impact.

        Partner with Tridev Innovation today, and let’s transform your boldest ideas into reality. Together, we’ll build a future fueled by creativity and success.
      </p>
      <div className="partners">
        <a href="https://www.nepatronix.org" rel="noreferrer" target="_blank"><img src={NT} alt="" /></a>
        <a href="https://www.hcm.edu.np" rel="noreferrer" target="_blank"><img src={HC} alt="" /></a>
        <a href="https://himalayansolution.com" rel="noreferrer" target="_blank"><img src={HS} alt="" /></a>
      </div>
    </div>

  )
}

export default About

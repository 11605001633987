import React from "react";
import "./Home.css";
import Showcase from "../Assets/Img/showcase.png";
import { Reveal } from "react-reveal";
const Home = () => {
  return (
    <div className="Hero-main">
      <div className="Hero">
        <div className="container">
          <div className="hero-text">
            <Reveal effect="slide-in-left">
              <h1>
                GROW <span>YOUR </span>
                <br />
                <span>BUSINESS </span>WITH US
              </h1>
            </Reveal>
            <Reveal effect="slide-in-left">
              <p>
                At Tridev Innovation, we are dedicated to creating groundbreaking solutions that drive progress and transform visions into impactful realities. By blending innovation with expertise, we empower businesses and individuals to achieve their full potential and shape a better future together.
              </p>
              <button className="explore-btn">
                Explore<span>&#11166;</span>
              </button>
            </Reveal>
          </div>
          <Reveal effect="slide-in-right">
            <div className="hero-img">
              <img src={Showcase} alt="" />
            </div>
          </Reveal>
        </div>
      </div>
    </div>
  );
};

export default Home;

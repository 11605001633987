import { useState } from "react";
import "./Navbar.css";
import React from "react";
import logo from "../Assets/Logo/Tridev-logo.svg";
import MenuIcon from "../Assets/Icons/nav-menu-icon.png";
// import { motion } from "framer-motion";
import { Link } from "react-scroll";




const Navbar = () => {
  const [navbar, setNavbar] = useState(false);
  const changebackground = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changebackground);
  return (
    <div className={navbar ? "navbar active" : "navbar"}>
      <div className="logo">
      <Link to = "Hero-main" smooth={true} offset={0} duration={500}><img  src={logo} alt="" /></Link>
      </div>
      <div className="nav-menu-items">
        <ul>
          <li><Link to = "Hero-main" smooth={true} offset={0} duration={500}>Home</Link></li>
          <li><Link to = "Main-ser" smooth={true} offset={-90} duration={500}>Services</Link></li>
          <li><Link to = "our-teams" smooth={true} offset={-90} duration={500}>Team</Link></li>
          <li><Link to = "about-page" smooth={true} offset={-90} duration={500}>About</Link></li>
          <li><Link to = "Home" smooth={true} offset={-90} duration={500}>Blogs&#x2197;</Link></li>
          <li><Link to = "Contact-page" smooth={true} offset={-90} duration={500}>Contact</Link></li>
        </ul>
        <img src={MenuIcon} alt="" />
      </div>
    </div>
  );
};

export default Navbar;

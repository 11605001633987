import React from "react";
import { Flip } from "react-reveal";
import "./Teams.css";
import photo1 from "../Assets/Img/milan2.jpg";
import photo2 from "../Assets/Img/dhanan.jpg";
import photo3 from "../Assets/Img/sachet.jpg";
const Teams = () => {
  return (
    <div className="main-teams">
      <h1 className="our-teams">Our Teams</h1>
      <div className="teams">
        <Flip right>
          <div className="team">
            <div className="pic-info">
              <img src={photo1} alt="" />
              <h3>
                Milan Pokharel{" "}
                <span>
                  <br />
                  Founder & CEO
                </span>
              </h3>
            </div>
            <p>
            As the Founder and CEO of Tridev Innovation, I am committed to driving innovation and delivering impactful solutions. With a vision for excellence and collaboration, I lead with passion, ensuring every project reflects our dedication to quality, growth, and meaningful results.
            </p>
          </div>
        </Flip>
        <Flip bottom>
          <div className="team">
            <div className="pic-info">
              <img src={photo2} alt="" />
              <h3>
                Dhananjaya Raut{" "}
                <span>
                  <br />
                  Founder & COO
                </span>
              </h3>
            </div>
            <p>
            As the Founder and COO of Tridev Innovation, I focus on ensuring seamless operations and aligning strategies with our goals. Driven by efficiency and collaboration, I work to optimize processes, empower teams, and deliver solutions that make a lasting impact.
            </p>
          </div>
        </Flip>
        <Flip right>
          <div className="team">
            <div className="pic-info">
              <img src={photo3} alt="" />
              <h3>
                Sachet Khatiwada{" "}
                <span>
                  <br />
                  Founder & CAO
                </span>
              </h3>
            </div>
            <p>
            As the Founder and CAO of Tridev Innovation, I specialize in creating a strong organizational foundation. With a focus on administration, planning, and resource management, I ensure the company operates smoothly and achieves sustained growth through innovative practices.
            </p>
          </div>
        </Flip>
      </div>
    </div>
  );
};

export default Teams;
